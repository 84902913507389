var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-row', [_c('v-select', {
    attrs: {
      "label": "SN curve",
      "items": _vm.snCurveSchemas.map(function (schema) {
        return schema.type;
      }),
      "value": _vm.value.type,
      "disabled": _vm.isGlobal,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function input($event) {
        return _vm.updateType($event);
      }
    }
  })], 1), _vm.value.type === 'LinearSNCurve' ? _c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Slope",
      "value": _vm.value.slope,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('slope', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Intercept stress",
      "value": _vm.value.intercept_stress,
      "units": "MPa",
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('intercept_stress', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Intercept cycles",
      "value": _vm.value.intercept_cycles,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('intercept_cycles', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Cutoff cycles",
      "value": _vm.value.cutoff_cycles,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('cutoff_cycles', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e(), _vm.value.type === 'EC3BilinearSNCurve' ? _c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Intercept stress",
      "value": _vm.value.intercept_stress,
      "units": "MPa",
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('intercept_stress', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Cutoff cycles",
      "value": _vm.value.cutoff_cycles,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('cutoff_cycles', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e(), _vm.value.type === 'ParametricBilinearSNCurve' ? _c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Intercept stress",
      "value": _vm.value.intercept_stress,
      "units": "MPa",
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('intercept_stress', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Cutoff cycles",
      "value": _vm.value.cutoff_cycles,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('cutoff_cycles', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Low cutoff cycles",
      "value": _vm.value.low_cutoff_cycles,
      "disabled": _vm.isGlobal,
      "placeholder": "0"
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('low_cutoff_cycles', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Slope 1",
      "value": _vm.value.slope1,
      "disabled": _vm.isGlobal,
      "placeholder": "3"
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('slope1', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Slope 2",
      "value": _vm.value.slope2,
      "disabled": _vm.isGlobal,
      "placeholder": "5"
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('slope2', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }