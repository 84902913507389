<template>
  <v-row>
    <v-col>
      <v-row>
        <v-select
          label="SN curve"
          :items="snCurveSchemas.map((schema) => schema.type)"
          :value="value.type"
          :disabled="isGlobal"
          :rules="[formRules.required]"
          @input="updateType($event)"
        />
      </v-row>

      <v-row v-if="value.type === 'LinearSNCurve'">
        <v-col>
          <numeric-text-field
            label="Slope"
            :value="value.slope"
            :disabled="isGlobal"
            @input="updateValue('slope', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Intercept stress"
            :value="value.intercept_stress"
            units="MPa"
            :disabled="isGlobal"
            @input="updateValue('intercept_stress', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Intercept cycles"
            :value="value.intercept_cycles"
            :disabled="isGlobal"
            @input="updateValue('intercept_cycles', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Cutoff cycles"
            :value="value.cutoff_cycles"
            :disabled="isGlobal"
            @input="updateValue('cutoff_cycles', $event, value)"
          />
        </v-col>
      </v-row>

      <v-row v-if="value.type === 'EC3BilinearSNCurve'">
        <v-col>
          <numeric-text-field
            label="Intercept stress"
            :value="value.intercept_stress"
            units="MPa"
            :disabled="isGlobal"
            @input="updateValue('intercept_stress', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Cutoff cycles"
            :value="value.cutoff_cycles"
            :disabled="isGlobal"
            @input="updateValue('cutoff_cycles', $event, value)"
          />
        </v-col>
      </v-row>

      <v-row v-if="value.type === 'ParametricBilinearSNCurve'">
        <v-col>
          <numeric-text-field
            label="Intercept stress"
            :value="value.intercept_stress"
            units="MPa"
            :disabled="isGlobal"
            @input="updateValue('intercept_stress', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Cutoff cycles"
            :value="value.cutoff_cycles"
            :disabled="isGlobal"
            @input="updateValue('cutoff_cycles', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Low cutoff cycles"
            :value="value.low_cutoff_cycles"
            :disabled="isGlobal"
            placeholder="0"
            @input="updateValue('low_cutoff_cycles', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Slope 1"
            :value="value.slope1"
            :disabled="isGlobal"
            placeholder="3"
            @input="updateValue('slope1', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Slope 2"
            :value="value.slope2"
            :disabled="isGlobal"
            placeholder="5"
            @input="updateValue('slope2', $event, value)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {schemaAdjust} from "@/utils/fp"
import NumericTextField from "@/components/common/NumericTextField";

export default {
  name: "SnCurve",
  components: { NumericTextField },
  props: ["value", "isGlobal"],
  computed: {
    snCurveSchemas () {
      return [
        {
          type: "LinearSNCurve",
          slope: undefined,
          intercept_stress: undefined,
          intercept_cycles: undefined,
          cutoff_cycles: undefined,
        }, 
        {
          type: "EC3BilinearSNCurve",
          intercept_stress: undefined,
          cutoff_cycles: undefined,
        },
        {
          type: "ParametricBilinearSNCurve",
          intercept_stress: undefined,
          cutoff_cycles: undefined,
          low_cutoff_cycles: undefined,
          slope1: undefined,
          slope2: undefined,
        },
      ]
    },
  },
  methods: {
    updateType (newType) {
      const schema = this.snCurveSchemas.find(a => a.type == newType)
      const newObj = {...this.value, type: newType}
      this.$emit("input", schemaAdjust(newObj, schema))
    },
  },
}
</script>
